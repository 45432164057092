input:not([type="radio"]):not([type="checkbox"]), button, textarea {
  -webkit-border-radius: 0;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -moz-appearance: none;
  appearance: none;
  font-family: inherit;
}

input[type="text"], input[type="email"], input[type="tel"], input[type="number"] {
  padding: 5px;
  border-radius: 0;
  outline: none;
  background: none;
  border:none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
  -moz-appearance:textfield;
}

button{
  display: block;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
}

input[type="radio"] {
  vertical-align: middle;
}

em {
  font-style: normal;
}

.error {
  color: red;
  &-msg {
    color: red;
  }
}

.form {
  .input {
    &-filed {
        width: 100%;
        border: 1px solid #000;
      &--small {
          @extend .input-filed; 
          width: 30%;
          &-x {
            @extend .input-filed; 
            width: 15%;
            @include mq(sp) {
              width: 25%;
            }
          }
      }
      &--mid {
          @extend .input-filed; 
          @include mq(md) {
            width: 70%;
          }
      }
    }
    &-label {
      margin-right: 5px;
  
    }
    &-require {
      &.error {
        background: #fadedf;
        border: 1px solid #ff8d85;
      }
    }
  }
  .select-btn {
    width: 150px;
    padding: 5px;
  }
}

td.flex {
  .input-label {
    @include mq(md) {
      flex-basis: 30%;
      max-width: 300px;
    }
  }
  @include mq(sp) {
    display: block;
  }
}


.btn-submit {
  background: $secondary-color;
  width: 300px;
  margin: 15px auto 0;
  padding: 10px;
  text-align: center;
  color: #ffffff;
  border-radius: 5px;
  transition: .3s;
  border: 1px solid $secondary-color;
  font-weight: bold;
  font-size: 2rem;
  @include mq(sp) {
    width: 100%;
    padding: 5vw 0;
    font-size: 3.5rem;
  }
  &:hover {
    background: #ffffff;
    color: $secondary-color;
  }
  &--normal {
    @extend .btn-submit;
    display: block;
    width: 250px;
    margin: 30px auto 0;
  }
  &-items {
    @include mq(sp) {
      flex-basis: 40vw;
    }
    &:nth-child(2) {
      margin-left: 20px;
      @include mq(sp) {
        margin-left: 3vw;
      }
    }
  }
  &-area {
    margin-top: 30px;
  }
}
