.products {
  &-front {
    margin-top: 13px;
    //itemのリスト
    &-items {
      &-box {
        margin-top: 36px;
      }
      &-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        @include mq(sp) {
          justify-content: space-between;
        }
        &__items {
          flex-basis: 20.3%;
          @include mq(md) {
            max-width: 195px;
          }
          @include mq(sp) {
            flex-basis: 48%;
          }
          &:nth-child(n+2) {
            @include mq(md) {
              margin-left: 6.26%;
            }
          }
          &:nth-child(n+3) {
            @include mq(sp) {
              margin-top: 5vw;
            }
          }
        }
      }
      &__caption {
        text-align: center;
        margin-top: 5px;
        font-weight: bold;
        color: #000000;
      }
    }
    &__ttl {
      margin-bottom: 45px;
    }
    //2カラムのリスト
    &-list {
      margin-top: 68px;
      &__inner {
        display: flex;
        align-items: flex-start;
      }
      &__thumb {
        flex-basis: 20.8%;
        max-width: 200px;
        border: 1px solid #666666;
      }
      &__txt {
        margin-left: 3.6%;
        flex-basis: 75.6%;
      }
      &__catch {
        font-weight: bold;
      }
      &__lead {
        margin-top: 5px;
      }
      &__items {
        &:nth-child(n+2) {
          margin-top: 50px;
          @include mq(sp) {
            margin-top: 5vw;
          }
        }
      }
    }
  }
  &-detail-note {
    margin-top: 60px;
    text-align: center;
  }
}

.goods {
  &-ttl {
    margin-top: 80px;
    @include mq(sp) {
      margin-top: 5vw;
    }
    &--frog {
      width: 294px;
      margin-left: auto;
      margin-right: auto;
      @include mq(sp) {
        width: 90%;
      }
    }
  }
  &-container {
    margin-top: 63px;
    @include mq(sp) {
      margin-top: 10vw;
    }
  }
  &-item {
    @include mq(md) {
      justify-content: space-between;
      display: flex;
    }
    &:nth-child(n+2) {
      margin-top: 100px;
      @include mq(sp) {
        margin-top: 20vw;
      }
    }
  }
  &-feature {
    max-width: 400px;
    flex-basis: 41.6%;
    @include mq(sp) {
      margin-top: 5vw;
    }
  }
  &-detail {
    flex-basis: 52.08%;
    max-width: 500px;
    &__brand {
      font-size: 1.8rem;
      @include mq(sp) {
        font-size: 3.5rem;
      }
    }
    &__name {
      display: block;
      font-size: 2rem;
      font-weight: bold;
      color: $secondary-color;
      @include mq(sp) {
        font-size: 4rem;
      }
    }
    &-info {
      margin-top: 55px;
      @include mq(sp) {
        margin-top: 5vw;
      }
      &-list {
        display: flex;
        align-items: flex-end;
        border-bottom: 1px solid #000;
        line-height: 1.3;
        &:nth-child(n+2) {
          margin-top: 10px;
        }
      }
      &__category {
        padding: 25px 0 10px;
        flex-basis: 100px;
        font-size: 1.4rem;
        font-weight: bold;
        text-align: center;
        @include mq(sp) {
          padding: 2vw 0 10px;
          font-size: 3rem;
        }
      }
      &__txt {
        position: relative;
        padding: 25px 10px 10px;
        flex-basis: 400px;
        word-wrap: break-word;
        max-width: 400px;
        @include mq(sp) {
          padding: 2vw 10px 10px;
        }
        &:after {
          content:"";
          position: absolute;
          background: #000000;
          height: 100%;
          width: 1px;
          left: 0;
          bottom: 2px;
        }
       }
    }
  }
  &-photo {
    margin-top: 50px;
    @include mq(sp) {
      margin-top: 5vw;
    }
  }
}
