.company {
  &-box {
    display: flex;
    @include mq(md) {
      justify-content: space-between;
    }
    @include mq(sp) {
      flex-direction: column;
    }
  }
  &-info {
    flex-basis: calc(100% - 41.7%);
    &__catch {
      flex-basis: 72px;
    }
    &__txt {
      margin-left: 37px;
      flex-basis: calc(100% - 72px);
    }
    &-list {
      display: flex;
    }
    &__items {
      &:nth-child(n+2) {
        margin-top: 10px;
      }
    }
  }
  &-photo {
    flex-basis: 41.7%;
    @include mq(sp) {
      margin-bottom: 3vw;
      order: -1;
    }
  }
}

.map {
  margin: 50px 0;
  height: 424px;
  @include mq(sp) {
    margin: 5vw 0;
  }
  iframe {
    width: 100%;
    height: 100%;
  }
}



