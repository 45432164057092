html {
  background: #FFF;
  font-size: calc(100vw / 96);
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  @include mq() {
    font-size: 62.5%;
  }
}
body {
  color: $font-color-default;
  font-size: 3.0rem;
  font-family: $font-family-default;
  font-weight: 300;
  line-height: 1.8;
  @include mq() {
    font-size: 1.6rem;
  }

}

img {
  max-width: 100%;
  vertical-align: bottom;
  backface-visibility: hidden;
}
svg {
  max-width: 100%;
  vertical-align: middle;
  backface-visibility: hidden;
}

a {
  text-decoration: none;
}
.alpha-img {
  img {
    transition: $transition;
  }
  &:hover {
    img {
      opacity: .6;
    }
  }
}
input:not([type="radio"]):not([type="checkbox"]),
button,
textarea {
  -webkit-border-radius: 0;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -moz-appearance: none;
  appearance: none;
  font-family: inherit;
}

.inner-pc {
  margin: 0 auto;
  @include mq(md) {
    max-width: 960px;
  }
}

.inner {
  max-width: 960px;
  margin: 0 auto;
  @include mq(sp) {
    width: 93.7%;
  }
}

.pc {
  @include mq(sp) {
    display: none;
  }
}
.sp {
  @include mq(md) {
    display: none;
  }
}

.wrapper {
  @include mq(sp) {
    // overflow: hidden;
  }

}

a {
  color: $base-color;
}

body {
  position: relative;
  @include mq(sp) {
    &.menu-open {
      &:after {
        content:"";
        background: rgba(#000,.3);
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 20;
      }
    }
  }

}
