.contact {
  &-wrap {
    max-width: 860px;
    margin: 0 auto;
  }
  &-intro {
    @include mq(md) {
      font-size: 1.3rem;
    }
    &-note {
      margin-top: 35px;
      @include mq(md) {
        font-size: 1.4rem;
      }
    }
  }
  &-list {
    width: 100%;
    margin-top: 35px;
    font-size: 1.4rem;
    @include mq(sp) {
      font-size: 3rem;
    }
    &__items {
      padding: 15px 0;
      vertical-align: top;
    }
    &__q {
      width: 130px;
      @include mq(sp) {
        width: 25vw;
      }
    }
    &__a {
      &-items {
        &:nth-child(n+2) {
          margin-top: 10px;
        }
      }
    }
  }
  &-attention {
    margin-bottom: 50px;
    text-align: center;
    font-size: 1.3rem;
    dt {
      font-weight: bold;
    }
  }
}

.confirm {
  .contact-list__items {
    padding-left: 10px;
  }
  .contact-list {
    border: 1px #e6e6e6 solid;
  }
  .contact-list__q {
    width: 200px;
    background: #e6e6e6;
    color: #000;
    border-bottom: 1px #fff solid;
    @include mq(sp) {
      width: 30vw;
    } 
  }
  .contact-list__a {
    border-bottom: 1px #ddd solid;
  }
  &-line {
    // border-bottom: 1px solid #000;  
  }
}