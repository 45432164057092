//animation
.fadeIn { opacity: 0;}
.fadeInUp {
  animation-fill-mode:both;
  animation-duration:1.5s;
  animation-name: fadeInUp;
}
@keyframes fadeInUp {
  0% { opacity: 0; transform: translateY(10px);}
  100% { opacity: 1; transform: translateY(0);}
}



@mixin zoom($width,$height) {
    .anime-zoom-target {
      overflow: hidden;
      width:$width;
      height:$height;
      img {
        transition: transform 0.2s linear;
      }
    }
    &:hover {
      img {
        transform: scale(1.2);
      }
  }
}



.anime-zoom {
  @include zoom(100%,auto);
}

.anime-fade {
  img {
    transition: .3s;
  }
  &:hover {
    img {
      opacity: 0.8;
    }
  }
}