.articlecard {
  &-item {
    &:nth-of-type(n+2) {
      margin-top: 48px;
      @include mq(sp) {
        margin-top: 15vw;
      }
    }
  }
  &-header {
    &__time {
      display: block;
      padding-bottom: 8px;
      border-bottom: 1px solid #000;
    }
    &__ttl {
      font-size: 2.2rem;
      @include mq(sp) {
        font-size: 3.5rem;
      }
    }
  }
  &-txt {
    margin-top: 24px;
    @include mq(sp) {
      margin-top: 5vw;
    }
    &:nth-of-type(n+2) {
      margin-top: 10px;
    }
  }
  &-media {
    margin-top: 32px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @include mq(sp) {
      margin-top: 5vw;
    }
    &__items {
      flex-basis: 46.8%;
      &:nth-child(even) {
        margin-left: 6.4%;
      }
      &:nth-child(n+3) {
        margin-top: 40px;
      }
    }
  }
}