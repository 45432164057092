.footer {
  margin-top: 100px;
  padding: 15px 0;
  border-top: 3px solid #000;
  background: #e6e6e6;
  font-size: 1.5rem;
  width: 100%;
  @include mq(sp) {
    margin-top: 15vw;
    padding: 5vw 0;
    font-size: 3rem;
  }
  &-nav {
    &-list {
      display: flex;
      @include mq(md) {
        justify-content: center;
      }
      @include mq(sp) {
        flex-wrap: wrap;
      }
      &__items {
          @include mq(md) {
            margin-right: 80px;
          }
          @include mq(sp) {
            flex-basis: 50%;
          }
          &:last-child {
            margin-right: 0;
          }
        a {
          color: #000000;
        }

      }
      &__link {
        font-weight: bold;
        @include mq(sp) {
          display: block;
          padding: 1vw 0 1vw 0;
        }
      }
    }

    &-child {
      margin-top: 5px;
      padding-left: 10px;
    }
    &-parent {
      @include mq(md) {
        margin-right: 62px;
      }
    }
  }
  &-copy {
    margin-top: 55px;
    text-align: center;
    font-size: 1.4rem;
    @include mq(sp) {
      font-size: 2.5rem;
    }
  }
}