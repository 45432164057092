/*
#overview
【Setting】
*/

/* ----------------------------------------------------
margin
---------------------------------------------------- */
/*
#styleguide
マージン
※それぞれ50まで設定してあります。
※頭文字のmをpに変えるとpaddingになります。
```
<p class="mt00">margin-top:  0px;</p>
<p class="mt05">margin-top:  5px;</p>
<p class="mt20">margin-top:  20px;</p>

<p class="mb00">margin-bottom:  0px;</p>
<p class="mb05">margin-bottom:  5px;</p>
<p class="mb20">margin-bottom:  20px;</p>

<p class="ml00">margin-left:  0px;</p>
<p class="ml05">margin-left:  5px;</p>
<p class="ml20">margin-left:  20px;</p>

<p class="mr00">margin-right:  0px;</p>
<p class="mr05">margin-right:  5px;</p>
<p class="mr20">margin-right:  20px;</p>
```

*/
.mt00 { margin-top:  0px !important; }
.mt05 { margin-top:  5px !important; }
.mt10 { margin-top: 10px !important; }
.mt15 { margin-top: 15px !important; }
.mt20 { margin-top: 20px !important; }
.mt25 { margin-top: 25px !important; }
.mt30 { margin-top: 30px !important; }
.mt35 { margin-top: 35px !important; }
.mt40 { margin-top: 40px !important; }
.mt45 { margin-top: 45px !important; }
.mt50 { margin-top: 50px !important; }
.mr00 { margin-right:  0px !important; }
.mr05 { margin-right:  5px !important; }
.mr10 { margin-right: 10px !important; }
.mr15 { margin-right: 15px !important; }
.mr20 { margin-right: 20px !important; }
.mr25 { margin-right: 25px !important; }
.mr30 { margin-right: 30px !important; }
.mr35 { margin-right: 35px !important; }
.mr40 { margin-right: 40px !important; }
.mr45 { margin-right: 45px !important; }
.mr50 { margin-right: 50px !important; }
.mb00 { margin-bottom:  0px !important; }
.mb05 { margin-bottom:  5px !important; }
.mb10 { margin-bottom: 10px !important; }
.mb15 { margin-bottom: 15px !important; }
.mb20 { margin-bottom: 20px !important; }
.mb25 { margin-bottom: 25px !important; }
.mb30 { margin-bottom: 30px !important; }
.mb35 { margin-bottom: 35px !important; }
.mb40 { margin-bottom: 40px !important; }
.mb45 { margin-bottom: 45px !important; }
.mb50 { margin-bottom: 50px !important; }
.ml00 { margin-left:  0px !important; }
.ml05 { margin-left:  5px !important; }
.ml10 { margin-left: 10px !important; }
.ml15 { margin-left: 15px !important; }
.ml20 { margin-left: 20px !important; }
.ml25 { margin-left: 25px !important; }
.ml30 { margin-left: 30px !important; }
.ml35 { margin-left: 35px !important; }
.ml40 { margin-left: 40px !important; }
.ml45 { margin-left: 45px !important; }
.ml50 { margin-left: 50px !important; }
.mt {
  /*
  #styleguide
  マージン（PCのみ）
  ※SP&タブレット時はすべて0になります。
  ※頭文字のmをpに変えるとpaddingになります。
  ```
  <p class="mt-pc-10">margin-top:  10px;</p>
  <p class="mt-pc-20">margin-top:  20px;</p>

  <p class="mb-pc-10">margin-bottom:  10px;</p>
  <p class="mb-pc-20">margin-bottom:  20px;</p>

  <p class="ml-pc-10">margin-left:  10px;</p>
  <p class="ml-pc-20">margin-left:  20px;</p>

  <p class="mr-pc-10">margin-right:  10px;</p>
  <p class="mr-pc-20">margin-right:  20px;</p>

  ```

  */
  /*
  #styleguide
  マージン（SPのみ）
  ※PC時はすべて0になります。
  ※頭文字のmをpに変えるとpaddingになります。
  ```
  <p class="mt-sp-10">margin-top:  10px;</p>
  <p class="mt-sp-20">margin-top:  20px;</p>

  <p class="mb-sp-10">margin-bottom:  10px;</p>
  <p class="mb-sp-20">margin-bottom:  20px;</p>

  <p class="ml-sp-10">margin-left:  10px;</p>
  <p class="ml-sp-20">margin-left:  20px;</p>

  <p class="mr-sp-10">margin-right:  10px;</p>
  <p class="mr-sp-20">margin-right:  20px;</p>

  ```

  */
  @include mq() {
    &-sp-10 { margin-top: 10px !important;}
    &-sp-15 { margin-top: 15px !important;}
    &-sp-20 { margin-top: 20px !important;}
    &-sp-25 { margin-top: 25px !important;}
    &-sp-30 { margin-top: 30px !important;}
    &-sp-35 { margin-top: 35px !important;}
    &-sp-40 { margin-top: 40px !important;}
    &-sp-45 { margin-top: 45px !important;}
    &-sp-50 { margin-top: 50px !important;}
  }
}
.mr {
  @include mq() {
    &-sp-10 { margin-right: 10px !important;}
    &-sp-15 { margin-right: 15px !important;}
    &-sp-20 { margin-right: 20px !important;}
    &-sp-25 { margin-right: 25px !important;}
    &-sp-30 { margin-right: 30px !important;}
    &-sp-35 { margin-right: 35px !important;}
    &-sp-40 { margin-right: 40px !important;}
    &-sp-45 { margin-right: 45px !important;}
    &-sp-50 { margin-right: 50px !important;}
  }
}
.mb {
  @include mq() {
    &-sp-10 { margin-bottom: 10px !important;}
    &-sp-15 { margin-bottom: 15px !important;}
    &-sp-20 { margin-bottom: 20px !important;}
    &-sp-25 { margin-bottom: 25px !important;}
    &-sp-30 { margin-bottom: 30px !important;}
    &-sp-35 { margin-bottom: 35px !important;}
    &-sp-40 { margin-bottom: 40px !important;}
    &-sp-45 { margin-bottom: 45px !important;}
    &-sp-50 { margin-bottom: 50px !important;}
  }
}
.ml {
  @include mq() {
    &-pc-05 { margin-left: 5px !important;}
    &-pc-10 { margin-left: 10px !important;}
    &-pc-15 { margin-left: 15px !important;}
    &-pc-20 { margin-left: 20px !important;}
    &-pc-25 { margin-left: 25px !important;}
    &-pc-30 { margin-left: 30px !important;}
    &-pc-35 { margin-left: 35px !important;}
    &-pc-40 { margin-left: 40px !important;}
    &-pc-45 { margin-left: 45px !important;}
    &-pc-50 { margin-left: 50px !important;}
  }
}


.mt {
  @include mq(md) {
    &-pc-10 { margin-top: 10px !important;}
    &-pc-15 { margin-top: 15px !important;}
    &-pc-20 { margin-top: 20px !important;}
    &-pc-25 { margin-top: 25px !important;}
    &-pc-30 { margin-top: 30px !important;}
    &-pc-35 { margin-top: 35px !important;}
    &-pc-40 { margin-top: 40px !important;}
    &-pc-45 { margin-top: 45px !important;}
    &-pc-50 { margin-top: 50px !important;}
  }
}
.mr {
  @include mq(md) {
    &-pc-10 { margin-right: 10px !important;}
    &-pc-15 { margin-right: 15px !important;}
    &-pc-20 { margin-right: 20px !important;}
    &-pc-25 { margin-right: 25px !important;}
    &-pc-30 { margin-right: 30px !important;}
    &-pc-35 { margin-right: 35px !important;}
    &-pc-40 { margin-right: 40px !important;}
    &-pc-45 { margin-right: 45px !important;}
    &-pc-50 { margin-right: 50px !important;}
  }
}
.mb {
  @include mq(md) {
    &-pc-10 { margin-bottom: 10px !important;}
    &-pc-15 { margin-bottom: 15px !important;}
    &-pc-20 { margin-bottom: 20px !important;}
    &-pc-25 { margin-bottom: 25px !important;}
    &-pc-30 { margin-bottom: 30px !important;}
    &-pc-35 { margin-bottom: 35px !important;}
    &-pc-40 { margin-bottom: 40px !important;}
    &-pc-45 { margin-bottom: 45px !important;}
    &-pc-50 { margin-bottom: 50px !important;}
  }
}
.ml {
  @include mq(md) {
    &-pc-10 { margin-left: 10px !important;}
    &-pc-15 { margin-left: 15px !important;}
    &-pc-20 { margin-left: 20px !important;}
    &-pc-25 { margin-left: 25px !important;}
    &-pc-30 { margin-left: 30px !important;}
    &-pc-35 { margin-left: 35px !important;}
    &-pc-40 { margin-left: 40px !important;}
    &-pc-45 { margin-left: 45px !important;}
    &-pc-50 { margin-left: 50px !important;}
  }
}
/* ----------------------------------------------------
padding
---------------------------------------------------- */
.pt00 { padding-top:  0px !important; }
.pt05 { padding-top:  5px !important; }
.pt10 { padding-top: 10px !important; }
.pt15 { padding-top: 15px !important; }
.pt20 { padding-top: 20px !important; }
.pt25 { padding-top: 25px !important; }
.pt30 { padding-top: 30px !important; }
.pt35 { padding-top: 35px !important; }
.pt40 { padding-top: 40px !important; }
.pt45 { padding-top: 45px !important; }
.pt50 { padding-top: 50px !important; }
.pr00 { padding-right:  0px !important; }
.pr05 { padding-right:  5px !important; }
.pr10 { padding-right: 10px !important; }
.pr15 { padding-right: 15px !important; }
.pr20 { padding-right: 20px !important; }
.pr25 { padding-right: 25px !important; }
.pr30 { padding-right: 30px !important; }
.pr35 { padding-right: 35px !important; }
.pr40 { padding-right: 40px !important; }
.pr45 { padding-right: 45px !important; }
.pr50 { padding-right: 50px !important; }
.pb00 { padding-bottom:  0px !important; }
.pb05 { padding-bottom:  5px !important; }
.pb10 { padding-bottom: 10px !important; }
.pb15 { padding-bottom: 15px !important; }
.pb20 { padding-bottom: 20px !important; }
.pb25 { padding-bottom: 25px !important; }
.pb30 { padding-bottom: 30px !important; }
.pb35 { padding-bottom: 35px !important; }
.pb40 { padding-bottom: 40px !important; }
.pb45 { padding-bottom: 45px !important; }
.pb50 { padding-bottom: 50px !important; }
.pl00 { padding-left:  0px !important; }
.pl05 { padding-left:  5px !important; }
.pl10 { padding-left: 10px !important; }
.pl15 { padding-left: 15px !important; }
.pl20 { padding-left: 20px !important; }
.pl25 { padding-left: 25px !important; }
.pl30 { padding-left: 30px !important; }
.pl35 { padding-left: 35px !important; }
.pl40 { padding-left: 40px !important; }
.pl45 { padding-left: 45px !important; }
.pl50 { padding-left: 50px !important; }
.pt {
  @include mq() {
    &-sp-10 { padding-top: 10px !important;}
    &-sp-15 { padding-top: 15px !important;}
    &-sp-20 { padding-top: 20px !important;}
    &-sp-25 { padding-top: 25px !important;}
    &-sp-30 { padding-top: 30px !important;}
    &-sp-35 { padding-top: 35px !important;}
    &-sp-40 { padding-top: 40px !important;}
    &-sp-45 { padding-top: 45px !important;}
    &-sp-50 { padding-top: 50px !important;}
  }
}
.pr {
  @include mq() {
    &-sp-10 { padding-right: 10px !important;}
    &-sp-15 { padding-right: 15px !important;}
    &-sp-20 { padding-right: 20px !important;}
    &-sp-25 { padding-right: 25px !important;}
    &-sp-30 { padding-right: 30px !important;}
    &-sp-35 { padding-right: 35px !important;}
    &-sp-40 { padding-right: 40px !important;}
    &-sp-45 { padding-right: 45px !important;}
    &-sp-50 { padding-right: 50px !important;}
  }
}
.pb {
  @include mq() {
    &-sp-10 { padding-bottom: 10px !important;}
    &-sp-15 { padding-bottom: 15px !important;}
    &-sp-20 { padding-bottom: 20px !important;}
    &-sp-25 { padding-bottom: 25px !important;}
    &-sp-30 { padding-bottom: 30px !important;}
    &-sp-35 { padding-bottom: 35px !important;}
    &-sp-40 { padding-bottom: 40px !important;}
    &-sp-45 { padding-bottom: 45px !important;}
    &-sp-50 { padding-bottom: 50px !important;}
  }
}
.pl {
  @include mq() {
    &-sp-10 { padding-left: 10px !important;}
    &-sp-15 { padding-left: 15px !important;}
    &-sp-20 { padding-left: 20px !important;}
    &-sp-25 { padding-left: 25px !important;}
    &-sp-30 { padding-left: 30px !important;}
    &-sp-35 { padding-left: 35px !important;}
    &-sp-40 { padding-left: 40px !important;}
    &-sp-45 { padding-left: 45px !important;}
    &-sp-50 { padding-left: 50px !important;}
  }
}
