.ttl {
  &-cmn {
    text-align: center;
    color: $secondary-color;
    font-weight: bold;
    font-size: 2rem;
    margin-left: auto;
    margin-right: auto;
    border-bottom: 3px solid #000;
    @include mq(md) {
      width: 202px;
    }
    @include mq(sp) {
      font-size: 4rem;
      width: 80vw;
    }
    &__inner {
      display: inline-block;
      padding-bottom: 8px;
      
    }
    &--long {
      @extend .ttl-cmn;
        @include mq(md) {
          width: 272px;
        }
      &__inner {
        @extend .ttl-cmn__inner;
      }
    }
    &--general {
      text-align: center;
      span {
        @extend .ttl-cmn;
        display: inline-block;
        width: auto;
        padding: 0 20px;
      }
    }
    &--no-border {
      @extend .ttl-cmn;
      border-bottom: 0;
      width: 100%;
    }
  }
}