
//ブレイクポイントをマップ型で定義
$breakpoints: (
  'sp': 'screen and (max-width: 767px)',//スマホ
  'touch': 'screen and (max-width: 1024px)',//タブレット縦～横
  'sm': 'screen and (min-width: 400px)',
  'md': 'screen and (min-width: 768px)',
  'lg': 'screen and (min-width: 1025px)',
  'xl': 'screen and (min-width: 1280px)',
) !default;

$font-color-default: #111;

$font-family-default: "hiragino kaku gothic pro","游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, sans-serif;

$transition: .3s;


//color
$base-color: #000;
$red: #112446;
$secondary-color: #112446;