.list {
  &-style--none {
    list-style-type: none;
  }
  &-number {
    padding-left: 1.5em;
  }
  &-txt-link {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-wrap: wrap;
    &__item {
      margin-right: 3vw;
      @include mq() {
        margin-right: 25px;
      }
    }
  }
}

.bread-list {
  // display: none;
  // @include mq() {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    padding: 5px 5px 0 10px;
    list-style-type: none;
    font-size: 1.2rem;
    align-items: center;
    @include mq(sp) {
      font-size: 2rem;
      line-height: 1.4;
    }
    a {
      color: inherit;
      &:hover {
        text-decoration: underline;
      }
    }
    li + li:before {
      margin: 0 10px;
      font-size: 1.0rem;
      content: '>';
      font-weight: normal;
      @include mq(sp) {
        font-size: 3rem;
      }
    }
    &__ico {
      margin-top: -2px;
      fill: #686868;
      &--white {
        fill: #FFF;
      }
    // }
  }
  &__items {
    &:last-child {
      font-weight: bold;
    }
  }
}