.privacy {
  &-box {
    margin-top: 20px;
  }
  &-ttl {
    font-weight: bold;
    &:nth-child(n+2) {
      margin-top: 10px;
    }
  }
  &-txt {
    padding-left: 1em;
    ul {
      li {
        text-indent: -1em;
        padding-left: 1em;
      }
    }
  }
  &-info {
    margin-top: 20px;
    text-align: right;
  }
}