/*
#overview
【Other】
*/



//icon
.ico {
}
.stroke {
  &-gray {
    stroke: #9098a1;
  }
}
.fill {
  &-gray {
    fill: #9098a1;
  }
  &-white {
    fill: #FFF;
  }
  &-yellow {
    fill: #facd46;
  }
}

//font
.font {
  &-red {
    color: #ff0000;
  }
  &-orange {
    color: #E18700;
  }
  &-bold {
    font-weight: bold;
  }
  &-black {
    color: #333;
  }
  &-white {
    color: #FFF;
  }
  &-gray {
    color: #999;
  }
  &-gold {
    color: #D99B31;
  }
  &-ex-sm {
    font-size: 1.0rem;
  }
  &-sm {
    font-size: 80%;
  }
  &-lg {
    font-size: 125%;
  }
  &-xl {
    font-size: 143%;
  }
  &-family-default {
    font-family: $font-family-default;
  }
}

//txt
.txt {
  &-attention {
    @include ico-list;
    &:before {
      content: '※';
    }
  }
  &-disc {
    @include ico-list;
    padding-left: 1.5em;
    &:before {
      content: '●';
    }
  }
  &-cube {
    @include ico-list;
    &:before {
      top: 3px;
      width: 13px;
      height: 13px;
      background: #000;
      content: '';
      @include mq() {
        top: 7px;
      }
    }
    &--black {
      @extend .txt-cube;
      &:before {
        background: #333;
      }
    }
  }
  &-ico {
    @include ico-list;
    &:before {
      top: 0;
      content: attr(data-txt);
    }
    &--red:before {
      color: $red;
    }
  }
  &-link {
    @include linkTxt;
    &--disabled {
      cursor: text;
      &:hover {
        text-decoration: none;
      }
    }
  }
  &-underline {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
  &-nav {
    &-md {
      display: inline-block;
      color: $font-color-default;
      font-size: 1.6rem;
      line-height: 1;
      @include mq() {
        font-size: 1.2rem;
      }
    }
    &-sm {
      display: inline-block;
      margin-top: 8px;
      color: #999;
      font-size: 1.2rem;
      line-height: 1;
      @include mq() {
        font-size: 1.0rem;
      }
    }
  }
}

//float
.float {
  &-left {
    @include mq(xl) {
      float: left;
    }
    @include mq() {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
  &-right {
    @include mq(xl) {
      float: right;
    }
    @include mq() {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

// vertical align
.valign {
  &-top {
    vertical-align: top !important;
  }
  &-middle {
    vertical-align: middle !important;
  }
  &-bottom {
    vertical-align: bottom !important;
  }
  &-baseline {
    vertical-align: baseline !important;
  }
}

//border
.border {
  padding: 0 5px;
  border: 1px solid #7f7f7f;
  &-bottom {
    border-bottom: 1px solid #7f7f7f;
  }
}

//text-align
.align {
  &-left {
    text-align: left;
  }
  &-center {
    text-align: center;
  }
  &-right {
    text-align: right;
  }
}

//position
.position {
  &-relative {
    position: relative;
  }
  &-absolute {
    position: absolute;
  }
}

//display
.display {
  &-block {
    display: block;
  }
  &-inline-block {
    display: inline-block;
  }
  &-inline {
    display: inline;
  }
}

.link {
  &-border {
    &:hover {
      text-decoration: underline;
    }
  }
}

.flex {
  display: flex;
}

.flex-center {
  justify-content: center;
}