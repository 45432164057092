


.gnav {
  @include mq(md) {
    padding: 40px 0 64px;
  }
  @include mq(sp) {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 100;
    background: #ffffff;
    transform: translateX(1000px);
    transition: .3s;
    opacity: 0;
  }
  &-list {
    @include mq(md) {
      display: flex;
      justify-content: space-between;
    }
    &__items {
      text-align: center;
      flex-basis: 16.8%;
      font-size: 1.7rem;
      @include mq(sp) {
        font-size: 3rem;
        border-top: 1px $secondary-color solid;
      }
    }
    &__link {
      position: relative;
      display: block;
      padding-bottom: 32px;
      color: $base-color;
      @include mq(sp) {
        padding: 8vw 3vw;
      }
      &:before {
        position: absolute;
        content:"";
        @include mq(md) {
          background: #000000;
          height: 3px;
          width: 100%;
          left: 0;
          bottom: 0;
          transition: .3s;
        }
        @include mq(sp) {
          right: 3vw;
          width: 2vw;
          height: 2vw;
          border-top: 1px solid $secondary-color;
          border-right: 1px solid $secondary-color;
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
          top: 50%;
          margin-top: -1vw;
        }
      }
      &:hover {
        border-width: 6px;
        &:before {
          height: 6px;
        }
      }
      &.current {
        @include mq(md) {
          &:before {
            height: 6px;
          }
        }
        @include mq(sp) {
          background: $secondary-color;
          color: #ffffff;
          &:before,&:after {
            border-color:#fff;
          }
        }
      }
    }
  }
  &.active {
    transform: translateX(0);
    opacity: 1;
    box-shadow: 3px 3px 3px rgba($secondary-color, 0.1);

  }
}

.mega-menu {
  position: relative;
  &-child {
    position: absolute;
    top: 100;
    left: -20px;
    background: #fff;
    z-index: 100;
    display: none;
    @include mq(md) {
      width: 202px;
    }
    @include mq(sp) {
      width: 100%;
      position: static;
      opacity: 1;
      border-top: 1px solid $secondary-color;
    }
    &__items {
      color: $secondary-color;
      font-size: 1.5rem;
      @include mq(sp) {
        font-size: 3rem;
      }
    }
    &__link {
      display: block;
      padding: 16px 0;
    }
  }
  &.active {
    .mega-menu-child {
      // -webkit-animation: fadeInUp;
      // -moz-animation: fadeInUp;
      // animation: fadeInUp;
      // animation-fill-mode:both;
      // animation-duration:0.5s;
      // -webkit-animation-fill-mode: both;
      // -moz-animation-fill-mode: both;
      // animation-fill-mode: both;
    }
    .gnav-list__link {
      &:before {
        @include mq(md) {
          height: 6px;
        }
      }
    }
  }
  &-trigger {
    &:before {
      @include mq(sp) {
        height: 15px;
        width: 2px;
        right: 3.5vw;
        top: 50%;
        margin-top: -7px;
        transform: rotate(0);
        border-top: none;
      }
    }
    &:after {
      @include mq(sp) {
        content:"";
        position: absolute;
        height: 2px;
        width: 15px;
        top: 50%;
        margin-top: -1px;
        right: calc(3vw - 5px);
        border-top: 1px $secondary-color solid;
      }
    }
    &.active {
      &:before {
        @include mq(sp) {
          content: none;
        }
      }
    }
    &.current {
      &:before,&:after {
        border-color:#fff;
      }
    }
    }
&:hover {
  .mega-menu-child {
    display: block;
    -webkit-animation: fadeInUp;
    -moz-animation: fadeInUp;
    animation: fadeInUp;
    animation-fill-mode:both;
    animation-duration:0.5s;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    animation-fill-mode: both;
  }
}
}


