.header {
  @include mq(sp) {
    // position: relative;
    position: fixed;
    z-index: 100;
    background: #ffffff;
    width: 100%;
    top: 0;
    left: 0;
  }
  &.fixed {
    // position: fixed;
    // width: 100%;
    // top: 0;
    // left: 0;
    box-shadow: 3px 3px 3px rgba($secondary-color,.1);
  }
  &-bar {
    padding: 30px 0;
    @include mq(sp) {
      display: flex;
      justify-content: space-between;
      padding: 5vw;
    }
  }
  &-logo {
    width: 221px;
    @include mq(md) {
      margin: 0 auto;
    }
    @include mq(sp) {
      flex-basis: 40vw;
    }
  }
  &-btn {
    &__inner {
      width: 30px;
      height: 30px;
      display: block;
      border-radius: 5px;

    }
    &:hover {
      cursor: pointer;
    }
    i {
      display: block;
      width: 30px;
      height: 1px;
      background: #000;
      transition: 0.5s;
      position: relative;
      left: 0;
      top: 10px;
      transition: .5s;
      &:before,
      &:after {
        content: "";
        display: block;
        width: 30px;
        height: 1px;
        background: #000;
        position: absolute;
        transform: rotate(0deg);
        transition: all 0.3s !important;
        @include mq(lg) {
          height: 2px;
          width: 6.25vw;
        }
      }
      &:before {
        transform: translateY(10px);
      }
      &:after {
        transform: translateY(-10px);

      }
    }
    @at-root {
      .menu-open {
        .header-btn__inner {
          i {
            background: #fff;
            &:after {
              transform: translateY(0px) rotate(-45deg);
            }
            &:before {
              transform: translateY(0px) rotate(45deg);
            }
          }
        }
      }
    }
  }
}