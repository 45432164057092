.hero {
  max-width: 960px;
  margin: 0 auto; 
}

.top {
  &-ttl {
    margin-bottom: 40px;
    @include mq(sp) {
      margin-bottom: 10vw;
    }
  }
  &-sec {
    & + & {
      padding-top: 154px;
      @include mq(sp) {
        padding-top: 10vw;
      }
    }
  }
  &-list {
    @include mq(md) {
      display: flex;
      justify-content: center;
    }
    &__items {
      flex-basis: 390px;
      max-width: 390px;
      &:nth-child(2) {
        @include mq(md) {
          margin-left: 80px;
        }
        @include mq(sp) {
          margin-top: 10vw;
        }
      }
    }

  }
  &-product {
    padding-top: 70px;
      @include mq(sp) {
        padding-top: 10vw;
      }
      &-list {
      &__txt {
        margin-top: 40px;
        @include mq(sp) {
          margin-top: 5vw;
        }
      }
      &__name {
        font-weight: bold;
        font-size: 1.8rem;
        @include mq(sp) {
          font-size: 4rem;
        }
        &-en {
          display: block;
          font-size: 1.4rem;
          font-weight: normal;
          @include mq(sp) {
            font-size: 3rem;
          }
        }
      }
      &__caption {
        margin-top: 40px;
        @include mq(sp) {
          margin-top: 3vw;
        }
      }
 
     }
  }
  &-news {
    &-list {
 
      &__date {
       &:nth-of-type(n+2)  {
         margin-top: 40px;
         @include mq(sp) {
           margin-top: 3vw;
         }
       }
      }
    }
  }
  &-more {
    &-list__items {
      &:nth-child(n+2) {
        margin-top: 20px;
      }
    }
  }
}

.sns-list {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  &__items {
    flex-basis: 56px;
    &:nth-child(n+2) {
      margin-left: 45px;
    }
  }
}

